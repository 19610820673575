.edit{
    background: none;
    border: none;
    position: absolute;
    top: 20px;
    .la{
        font-size: 24px;
    }
}
.en .edit{
    right: 20px;
    left: auto;
}
.ar .edit{
    left: 20px;
    right: auto;
}